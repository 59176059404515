<template>
  <div @click="onClickLink()" class="banner-top" :style="customStyle">
    <img class="img" :src="image" />
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
    marginBottom: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    customStyle() {
      return {
        cursor: this.link ? 'pointer' : 'default',
        // backgroundImage: `url(${this.image})`,
        marginBottom: this.marginBottom ? '28px' : '0',
      }
    },
  },

  methods: {
    onClickLink() {
      if (this.link) {
        window.open(this.link, '_blank')
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
.banner-top
    // height: 60px
    // background-color: rgb(48, 79, 107)
    top: 80px
    width: 100%
    margin-top: -70px
    background-position:center center
    background-repeat: no-repeat
    background-size: contain
    visibility: visible
    object-fit: contain
    @media small
      margin-top: -70px
      min-height: 60px
      background-position:center center
      background-size: cover
    @media medium
      margin-top: -70px
      min-height: 50px
      background-position:center center
      background-size: cover

.img
    // max-height: 60px
    width: 100%
    object-fit: contain
    @media small
      min-height: 60px
      max-height: 60px
      width: 100%
      object-fit: cover
    @media medium
      min-height: 70px
      max-height: 70px
      width: 100%
      object-fit: cover
</style>
