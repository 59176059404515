<template>
  <div>
    <Load v-if="campaignLoading" />
    <template v-else>
      <div
        v-if="
          stickyBanner ||
            contentBanner ||
            campaignData ||
            contentPromotion
        "
        class="campaign-list"
      >
        <Banner
          v-if="stickyBanner"
          :image="stickyBanner"
          :link="stickyLink"
          :marginBottom="false"
        />
        <div
          v-if="contentBanner"
          v-html="contentBanner"
          class="pb-8 content-banner html-content"
        />
        <div
          v-for="(item, index) in campaignData"
          :class="customClass(index + 1)"
          :key="index"
          class="py-8"
        >
          <template v-if="item.status === 'active'">
            <v-row v-bind="{ justify: 'center' }">
              <h2 :class="customClassTitle(index)">
                End in
              </h2>
            </v-row>
            <v-row class="mt-8" v-bind="{ justify: 'center' }">
              <CountDown
                v-if="getTimeLeft(item) > -1"
                :timeLeft="getTimeLeft(item)"
                :dark="isOdd(index)"
                @end="onEndCountdown"
              />
            </v-row>
            <div v-if="item.banner" class="banner mt-8">
              <div v-html="item.banner" class="html-content"></div>
            </div>
          </template>
          <template v-else-if="item.status === 'incoming'">
            <v-row v-bind="{ justify: 'center' }">
              <h2 :class="customClassTitle(index)">Start in</h2>
            </v-row>
            <v-row class="mt-8" v-bind="{ justify: 'center' }">
              <CountDown
                v-if="getTimeLeft(item) > -1"
                :timeLeft="getTimeLeft(item)"
                :dark="isOdd(index)"
                @end="onEndCountdown"
              />
            </v-row>
            <div v-if="item.banner" class="banner mt-8">
              <div v-html="item.banner" class="html-content"></div>
            </div>
          </template>
          <!-- <div class="end-line"></div> -->
        </div>

        <div
          v-if="contentPromotion"
          v-html="contentPromotion"
          class="py-6 content-promotion html-content"
        />
      </div>
      <div
        v-if="!campaignData"
        class="full-page content-center"
      ></div>
    </template>
  </div>
</template>

,
<script>
import Load from './Load'
import CountDown from '@/components/bnn/CountDown'
import Banner from '@/components/bnn/Banner'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      campaignLoading: state => state.campaign.isLoading,
      campaignData: state => state.campaign?.campaignList?.campaign,
      contentData: state => state.campaign?.campaignList?.content,
    }),

    contentBanner() {
      return this.contentData?.banner
    },

    contentPromotion() {
      return this.contentData?.promotion
    },

    stickyBanner() {
      return this.contentData?.sticky_top
    },

    stickyLink() {
      return this.contentData?.sticky_link
    },
  },

  async mounted() {
    await this.$store.dispatch('campaign/getCampaignList')
  },

  methods: {
    getTimeLeft(item) {
      if (item.status === 'active') {
        return (
          (item?.ended_at_timestamp -
            item.current_datetime_timestamp) *
            1000 ?? -1
        )
      }

      if (
        item.started_at_timestamp &&
        item.current_datetime_timestamp
      ) {
        return (
          (item.started_at_timestamp -
            item.current_datetime_timestamp) *
          1000
        )
      }

      return -1
    },

    isOdd(num) {
      return num % 2 !== 0
    },

    customClass(num) {
      return this.isOdd(num) ? 'dark' : 'light'
    },

    customClassTitle(num) {
      return this.isOdd(num) ? 'text-black' : 'text-white'
    },

    onEndCountdown() {
      if (!this.htmlAfterStart) {
        setTimeout(async () => {
          this.$router
            .push({
              name: 'ProductDetail',
              params: {
                slug: this.slug,
              },
            })
            .catch(() => true)
        }, 300)
      }
    },
  },

  components: {
    Load,
    CountDown,
    Banner,
  },
}
</script>

<style lang="stylus" scoped>

.campaign-list {
  margin-top: 150px;
}

.content-banner
    width: 100%;
    display: flex
    justify-content: center
    align-items: center

.content-promotion
    width: 100%
    display: flex
    justify-content: center
    align-items: center

.banner
    width: 100%
    display: flex
    justify-content: center
    align-items: center

.dark
    background: #000
    width: 100%
    padding-top:2rem

.white
    background: white
    width: 100%
    padding-top:2rem

.text-black
    color: $color-bnn-gray-medium

.text-white
    color: white

.end-line
    background $color-bnn-gray-light-2
    width 100%
    height 1.25rem
    margin-top: 4rem
</style>
